"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _scopeCss = require("./scopeCss");

Object.defineProperty(exports, "scopeCss", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_scopeCss).default;
  }
});

var _createProcessor = require("./createProcessor");

Object.defineProperty(exports, "createProcessor", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_createProcessor).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }